import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/Header';
import ProductsInStock from '../components/products/ProductsInStock';

const InStock = () => {
  return (
    <Layout>
      <SEO title='Organic Plants In Stock' />
      <Header />
      <ProductsInStock />
    </Layout>
  );
};

export default InStock;
