import { Link } from 'gatsby';
import React from 'react';
import ProductCategories from './products/ProductCategories';
import logo1x from '../images/logo@1x.png';
import logo2x from '../images/logo@2x.png';

const Header = () => (
  <div className='header'>
    <Link to='/' className='logo'>
      <img src={logo1x} srcSet={`${logo2x} 2x`} alt='Plug trays from Mountain Valley Growers logo' />
    </Link>
    <nav>
      <ProductCategories />
    </nav>
  </div>
);

export default Header;
