import _ from 'lodash';
import React, { useState } from 'react';
import { Product } from './ProductGrid';
import { useProductsApiQuery } from '../hooks';
import LoadingError from '../LoadingError';

const ProductsInStock = () => {
  const products = useProductsApiQuery({ type: 'instock' });
  const [sortBy, setSortBy] = useState('BotanicalName');

  const sortByChanged = (e) => {
    const value = e.target.value;
    setSortBy(value);
  };
  const sortByRendered = (
    <>
      <label htmlFor='product-grid--sort-box'>Sort by:</label>
      <select id='product-grid--sort-box' onChange={sortByChanged} value={sortBy}>
        <option value='BotanicalName'>Botanic name</option>
        <option value='CommonName'>Common name</option>
      </select>
    </>
  );

  const renderedProducts = _.map(_.sortBy(products.data || [], [sortBy]), Product);
  return (
    <div className='grid-x grid-margin-x grid-margin-y grid-padding-x grid-padding-y'>
      <LoadingError error={products.error} />
      <div className='cell small-12 medium-8'>
        <h1>Organic plug trays in stock</h1>
        <span>({_.size(products.data)} plants)</span>
      </div>

      <div className='cell small-12 medium-4 product-grid--sort'>{sortByRendered}</div>
      {renderedProducts}
    </div>
  );
};

export default ProductsInStock;
