import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { AcornGraphqlError } from '../fetcher';

const LoadingError = ({ error }) => {
  if (!error) {
    return null;
  }
  let errorsSerialized = 'unspecified server error';
  if (error instanceof AcornGraphqlError) {
    errorsSerialized = error.toString();
  }

  return (
    <div className='cell small-12 callout alert'>
      <h3>Error</h3>
      <p>Error occurred loading data: {errorsSerialized}</p>
      <p>Please reload the page to try again.</p>
    </div>
  );
};

LoadingError.propTypes = {
  error: PropTypes.any,
};

export default LoadingError;
