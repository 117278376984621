import React from 'react';
import _ from 'lodash';
import config from '../../config';
import { Maybe } from '../generated/types-and-hooks';

const ENV_CONFIG = config.get();

export enum ImageSize {
  Original = 'original',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum ImageType {
  Jpeg = 'jpg',
  Webp = 'webp',
}

export const IMAGE_SIZE_MEDIUM = 'medium';
export const IMAGE_SIZE_SMALL = 'small';
export const IMAGE_SIZE_LARGE = 'large';

export const DEFAULT_IMAGE_HASH = 'image-placeholder-98f519';
export const GIFT_CARD_HASH = 'gift-card-f8e886';

export const generateImageCdnPath = (
  imageHash: string | undefined | null,
  size: ImageSize,
  type: ImageType = ImageType.Jpeg
): string => {
  if (!_.includes([ImageSize.Original, ImageSize.Small, ImageSize.Medium, ImageSize.Large], size)) {
    throw new Error(`generateImageCdnPath: invalid size:${size}`);
  }
  return `${ENV_CONFIG.imageCdnPrefix}/${imageHash || DEFAULT_IMAGE_HASH}-${size}.${type}`;
};

export const renderGiftCardImage = (size: ImageSize): JSX.Element => (
  <img alt='Gift Card Image' src={generateImageCdnPath(GIFT_CARD_HASH, size)} />
);
