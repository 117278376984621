import _ from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getProductLink } from '../../utils/cart';
import { useHydrateProducts } from '../hooks';
import { generateImageCdnPath, ImageSize } from '../../utils/images';

export const Product = (product) => {
  const { Id, BotanicalName, CommonName, NamePlugtrays, PricePlugtrays, InStockPlugtrays, Slug, ImageHashes } = product;
  const imageUrl = generateImageCdnPath(_.get(ImageHashes, [0]), ImageSize.Small);

  let renderedStock = <link itemProp='availability' href='http://schema.org/InStock' />;
  if (InStockPlugtrays === 0) {
    renderedStock = (
      <p className='product-thumb--link__notify'>
        <link itemProp='availability' href='http://schema.org/OutOfStock' />
        Temporarily out of stock &middot;{' '}
        <Link to={getProductLink(product)} className='product-thumb--link__product'>
          Notify me
        </Link>
      </p>
    );
  }

  return (
    <div
      key={Id}
      className='cell small-12 medium-6 large-3 product-thumb--card'
      itemProp='itemListElement'
      itemType='http://schema.org/ListItem'
      itemRef='plugtrays-org'
    >
      <Link to={getProductLink(product)} className='product-thumb--link__product' itemProp='url'>
        <meta itemProp='image' content={imageUrl} />
        <div
          className='product-thumb--image'
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
          data-testid={Slug}
        />
        {BotanicalName ? (
          <p className='product-thumb--title' itemProp='name'>
            <em>{BotanicalName}</em> <br />
            <strong>{CommonName}</strong>
          </p>
        ) : (
          <p className='product-thumb--title' itemProp='name'>
            <strong>{NamePlugtrays}</strong>
          </p>
        )}
        <div itemProp='offers' itemScope itemType='http://schema.org/Offer'>
          <p className='product-thumb---price'>
            <span itemProp='priceCurrency' content='USD'>
              $
            </span>
            <span itemProp='price' content={PricePlugtrays}>
              {PricePlugtrays}
            </span>{' '}
            / tray of 128 plants
          </p>
        </div>
      </Link>
      {renderedStock}
    </div>
  );
};

export const ProductGrid = ({ staticProducts, sortBy }) => {
  const ProductIds = _.map(staticProducts, ({ Id }) => Id);
  ProductIds.sort();
  const productContext = useHydrateProducts({ ProductIds });

  const apiProductsById = _.keyBy(productContext.data, ({ Id }) => Id);
  const mergedProducts = _.map(staticProducts, (product) => {
    const apiProduct = _.get(apiProductsById, product.Id);
    if (!apiProduct) {
      // log.error({product}, 'ProductGrid: could not find matching product from api response');
      return product;
    }
    return {
      ...product,
      ...apiProduct,
    };
  });

  const renderedProducts = _.map(_.sortBy(mergedProducts, [sortBy]), Product);
  return <Fragment>{renderedProducts}</Fragment>;
};

ProductGrid.defaultProps = {
  staticProducts: [],
  Tag: '',
  sortBy: '',
};

ProductGrid.propTypes = {
  staticProducts: PropTypes.array,
  Tag: PropTypes.string,
  sortBy: PropTypes.string,
};

ProductGrid.displayName = 'ProductGrid';

export default ProductGrid;
